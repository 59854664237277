import { useState } from "react";
import { Icon, Tiles } from "src/components";
import { Category, expectNever } from "src/utils";
import Dialog from "@mui/material/Dialog";
import { CategoryForm } from "./CategoryForm";
import { ConfirmDialog } from "src/components";
import { view, ViewModel } from "@yoskutik/react-vvm";
import { CategoriesService } from "src/services";
import { injectable } from "tsyringe";
import { makeObservable, observable } from "mobx";

type CategoryItem = Category & { id: number; active?: boolean };

const addCategory: CategoryItem = {
  id: -1,
  icon: "Add",
  label: "Add",
  active: true,
};

type State =
  | { type: "idle" }
  | { type: "new" }
  | { type: "edit"; id: number }
  | { type: "delete"; id: number };

@injectable()
class CategoriesViewModel extends ViewModel {
  @observable state: State = { type: "idle" };

  constructor(private categoriesService: CategoriesService) {
    super();

    makeObservable(this);
  }

  get categories() {
    return this.categoriesService.categories;
  }

  get currentCategory() {
    switch (this.state.type) {
      case "idle":
      case "new":
        return undefined;

      case "edit":
      case "delete":
        return this.categoriesService.find(this.state.id);

      default:
        return expectNever(this.state);
    }
  }

  onCreateClick = () => {
    this.state = { type: "new" };
  };

  onEditClick(id: number) {
    this.state = { type: "edit", id };
  }

  onDeleteClick(id: number) {
    this.state = { type: "delete", id };
  }

  onCancelClick = () => {
    this.state = { type: "idle" };
  };

  onCancelDeleteClick = () => {
    if (this.state.type !== "delete") {
      return;
    }

    this.state = { type: "edit", id: this.state.id };
  };

  onSaveClick = ({ icon, label }: { icon: Icon; label: string }) => {
    switch (this.state.type) {
      case "idle":
        return;

      case "new":
        this.categoriesService.add(icon, label);

        return this.onCancelClick();

      case "edit":
        this.categoriesService.update(this.state.id, icon, label);

        return this.onCancelClick();

      case "delete":
        return;

      default:
        return expectNever(this.state);
    }
  };

  onConfirmClick = () => {
    switch (this.state.type) {
      case "idle":
      case "new":
      case "edit":
        return;

      case "delete":
        this.categoriesService.remove(this.state.id);

        return this.onCancelClick();

      default:
        return expectNever(this.state);
    }
  };
}

export const Categories = view(CategoriesViewModel)(({ viewModel }) => {
  const renderDialog = () => {
    switch (viewModel.state.type) {
      case "idle":
        return null;

      case "new":
        return (
          <CategoryForm
            title="New category"
            saveActionLabel="Add"
            onSave={viewModel.onSaveClick}
            onCancel={viewModel.onCancelClick}
          />
        );

      case "edit":
      case "delete": {
        const category = viewModel.currentCategory;

        if (!category) {
          return null;
        }

        return (
          <>
            <CategoryForm
              title="Edit category"
              saveActionLabel="Save"
              category={category}
              onSave={viewModel.onSaveClick}
              onCancel={viewModel.onCancelClick}
              onDelete={() => viewModel.onDeleteClick(category.id)}
            />
            <Dialog
              open={viewModel.state.type === "delete"}
              onClose={viewModel.onCancelDeleteClick}
            >
              <ConfirmDialog
                title={`Are you sure you want to delete "${category.label}"?`}
                yesNo
                onConfirm={viewModel.onConfirmClick}
                onCancel={viewModel.onCancelDeleteClick}
              />
            </Dialog>
          </>
        );
      }

      default:
        return expectNever(viewModel.state);
    }
  };

  const dialogContent = renderDialog();
  const isDialogShown = Boolean(dialogContent);

  return (
    <>
      <Tiles
        tiles={viewModel.categories.concat([addCategory])}
        onSelect={(item) => {
          if (item.id === -1) {
            viewModel.onCreateClick();
          } else {
            viewModel.onEditClick(item.id);
          }
        }}
      />
      <Dialog open={isDialogShown} onClose={viewModel.onCancelClick}>
        {dialogContent}
      </Dialog>
    </>
  );
});
