import { singleton } from "tsyringe";

const ROOT_KEY = "cashback-tracker";

@singleton()
export class StorageService {
  private getPath(key: string): string {
    return `${ROOT_KEY}/${key}`;
  }

  read<T>(key: string): T | undefined {
    const serialized = localStorage.getItem(this.getPath(key));

    if (!serialized) {
      return undefined;
    }

    return JSON.parse(serialized) as T;
  }

  write<T>(key: string, value: T): void {
    const serialized = JSON.stringify(value);

    localStorage.setItem(this.getPath(key), serialized);
  }
}
