import { makeObservable, observable, action } from "mobx";
import type { Icon } from "src/components";
import type { Category } from "src/utils";
import { byId, maxId } from "src/utils/id";
import { singleton } from "tsyringe";
import { StorageService } from "./StorageService";

@singleton()
export class CategoriesService {
  @observable categories: Category[];

  constructor(private storageService: StorageService) {
    makeObservable(this);

    this.categories = this.storageService.read("categories") ?? [];
  }

  private getNewId(): number {
    return this.categories.reduce(maxId, -1) + 1;
  }

  private findIndex(id: number): number {
    return this.categories.findIndex(byId(id));
  }

  private store(): void {
    this.storageService.write("categories", this.categories);
  }

  @action add(icon: Icon, label: string): void {
    this.categories.push({
      id: this.getNewId(),
      icon,
      label,
    });

    this.store();
  }

  @action update(id: number, icon: Icon, label: string): void {
    const category = this.find(id);

    if (category && (category.icon !== icon || category.label !== label)) {
      category.icon = icon;
      category.label = label;

      this.store();
    }
  }

  @action remove(id: number): void {
    const index = this.findIndex(id);

    if (index !== -1) {
      this.categories.splice(index, 1);

      this.store();
    }
  }

  find(id: number): Category | undefined {
    return this.categories.find(byId(id));
  }

  set(categories: Category[]) {
    this.categories = categories;

    this.store();
  }
}
