import { makeObservable, observable } from 'mobx';
import type { Cashback, CashbackConfig, CashbackSet } from 'src/utils';
import { byId, maxId } from 'src/utils/id';
import { singleton } from 'tsyringe';
import { StorageService } from './StorageService';

@singleton()
export class CashbackService {
  @observable cashback: CashbackConfig;

  constructor(private storageService: StorageService) {
    makeObservable(this);

    this.cashback = this.storageService.read('cashback') ?? {
      items: [],
      current: -1,
    };
  }

  private store() {
    this.storageService.write('cashback', this.cashback);
  }

  private findIndex(id: number) {
    return this.cashback.items.findIndex(byId(id));
  }

  get currentConfig() {
    return this.find(this.cashback.current);
  }

  find(id: number) {
    return this.cashback.items.find(byId(id));
  }

  select(id: number) {
    this.cashback.current = id;

    this.store();
  }

  delete(id: number) {
    if (this.cashback.current === id) {
      return;
    }

    const index = this.findIndex(id);

    if (index !== -1) {
      this.cashback.items.splice(index, 1);

      this.store();
    }
  }

  private getNewId(): number {
    return this.cashback.items.reduce(maxId, -1) + 1;
  }

  add(label: string): number {
    const id = this.getNewId();

    this.cashback.items.push({ id, label, items: [] });
    if (this.cashback.items.length === 1) {
      this.cashback.current = id;
    }

    this.store();

    return id;
  }

  rename(id: number, label: string) {
    const item = this.find(id);

    if (item && item.label !== label) {
      item.label = label;

      this.store();
    }
  }

  setValue(
    id: number,
    cardId: number,
    categoryId: number,
    value: number,
    hint?: string
  ) {
    const config = this.find(id);
    const item = config?.items.find(byIds(cardId, categoryId));

    if (item) {
      if (item.value === value && item.hint === hint) {
        return;
      }

      item.value = value;
      item.hint = hint;
    } else {
      if (!config) {
        return;
      }

      config.items.push({ card: cardId, category: categoryId, value, hint });
    }

    this.store();
  }

  resetValue(id: number, cardId: number, categoryId: number) {
    const config = this.find(id);

    if (!config) {
      return;
    }

    const index = config?.items.findIndex(byIds(cardId, categoryId));

    if (index !== -1) {
      config.items.splice(index, 1);

      this.store();
    }
  }

  set(cashback: CashbackConfig) {
    this.cashback = cashback;

    this.store();
  }
}

function byIds(cardId: number, categoryId: number) {
  return (item: Cashback) =>
    item.card === cardId && item.category === categoryId;
}
