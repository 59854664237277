export type WithId = { id: number };

export function maxId<T extends WithId>(max: number, item: T): number {
  return Math.max(max, item.id);
}

export const byId =
  <T extends WithId>(id: number) =>
  (item: T) =>
    item.id === id;
