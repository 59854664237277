import { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Button from "@mui/material/Button";

export type InputDialogProps = {
  title: string;
  label: string;
  saveActionLabel?: string;
  defaultValue?: string;
  type?: TextFieldProps["type"];
  onSave: (name: string) => void;
  onCancel: VoidFunction;
};

export const InputDialog = ({
  title,
  label,
  saveActionLabel = "Save",
  defaultValue,
  type = "text",
  onSave,
  onCancel,
}: InputDialogProps) => {
  const [value, setValue] = useState(defaultValue ?? "");

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ minWidth: 300 }}>
        <TextField
          autoFocus
          margin="dense"
          label={label}
          fullWidth
          variant="standard"
          value={value}
          type={type}
          inputProps={{ pattern: type === "number" ? "[0-9]*" : undefined }}
          onChange={(event) => setValue(event.target.value)}
          onKeyUp={(event) => {
            if (value && event.key === "Enter") {
              onSave(value);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onSave(value)} disabled={!value}>
          {saveActionLabel}
        </Button>
      </DialogActions>
    </>
  );
};
