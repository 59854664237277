import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Paper from "@mui/material/Paper";

import styles from "./Tile.module.css";
import classNames from "classnames";
import { ReactNode } from "react";
import { Icons, Icon } from "../icons";
import { Badge } from "@mui/material";

export type TileProps = {
  icon: Icon;
  children?: ReactNode;
  badge?: number;
  active?: boolean;
  disabled?: boolean;
  large?: boolean;
  onClick: VoidFunction;
};

export const Tile = ({
  icon,
  children,
  badge,
  active,
  disabled,
  large,
  onClick,
}: TileProps) => {
  const Icon = Icons[icon];
  const color = active ? "primary" : undefined;
  const iconElement = (
    <Icon fontSize={large ? "large" : "medium"} color={color} />
  );

  return (
    <Paper
      className={classNames(
        styles.tile,
        active && styles.active,
        disabled && styles.disabled
      )}
    >
      <ButtonBase
        onClick={onClick}
        className={styles.button}
        disabled={disabled}
      >
        <CardContent className={styles.card}>
          {badge !== undefined ? (
            <Badge badgeContent={badge} color="info">
              {iconElement}
            </Badge>
          ) : (
            iconElement
          )}
          {children && (
            <Typography
              variant="caption"
              component="div"
              className={styles.label}
              color={color}
            >
              {children}
            </Typography>
          )}
        </CardContent>
      </ButtonBase>
    </Paper>
  );
};
