import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export type InfoDialogProps = {
  title: string;
  content: string;
  closeActionLabel?: string;
  onClose: VoidFunction;
};

export const InfoDialog = ({
  title,
  content,
  closeActionLabel = 'Ok',
  onClose,
}: InfoDialogProps) => (
  <>
    <DialogTitle sx={{ minWidth: 300 }}>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>{closeActionLabel}</Button>
    </DialogActions>
  </>
);
