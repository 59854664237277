import { makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';
import { StorageService } from './StorageService';

export type Theme = 'light' | 'dark' | 'auto';

@singleton()
export class ThemeService {
  @observable theme: Theme;

  constructor(private storageService: StorageService) {
    makeObservable(this);

    this.theme = this.storageService.read('theme') ?? 'auto';
  }

  private store(): void {
    this.storageService.write('theme', this.theme);
  }

  set(theme: Theme) {
    this.theme = theme;

    this.store();
  }
}
