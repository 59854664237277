import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Badge, Box, Dialog } from "@mui/material";
import EditIcon from "@mui/icons-material/EditOutlined";
import { CashbackSet, expectNever } from "src/utils";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { view, ViewModel } from "@yoskutik/react-vvm";
import { CardsService, CashbackService } from "src/services";
import { injectable } from "tsyringe";
import { FC } from "react";
import { makeObservable, observable } from "mobx";
import { Drawer, Icon, InputDialog } from "src/components";
import { CardCashback } from "./CardCashback";

export type CashbackProps = {
  config: CashbackSet;
};

type CardConfig = {
  id: number;
  label: string;
  bindings: number;
};

type CategoryConfig = {
  id: number;
  icon: Icon;
  label: string;
  badge?: number;
};

type State =
  | { type: "idle" }
  | { type: "rename" }
  | { type: "select"; id: number };

@injectable()
class CashbackViewModel extends ViewModel<unknown, CashbackProps> {
  @observable state: State = { type: "idle" };
  constructor(
    private cardService: CardsService,
    private cashbackService: CashbackService
  ) {
    super();
    makeObservable(this);
  }

  get cards(): CardConfig[] {
    return this.cardService.cards.map((card) => ({
      ...card,
      bindings: this.viewProps.config.items.filter(
        (item) => item.card === card.id
      ).length,
    }));
  }

  get currentCard() {
    if (this.state.type !== "select") {
      return undefined;
    }
    return this.cardService.find(this.state.id);
  }

  onRenameClick = () => {
    this.state = { type: "rename" };
  };

  onSaveClick = (label: string) => {
    this.cashbackService.rename(this.viewProps.config.id, label);

    this.onCancelClick();
  };

  onCardClick(id: number) {
    this.state = { type: "select", id };
  }

  onCancelClick = () => {
    this.state = { type: "idle" };
  };
}

export const Cashback: FC<CashbackProps> = view(CashbackViewModel)(
  ({ viewModel, config }) => {
    const renderDrawer = () => {
      switch (viewModel.state.type) {
        case "idle":
        case "rename":
          return null;

        case "select":
          const card = viewModel.currentCard;

          if (!card) {
            return null;
          }

          return <CardCashback card={card} config={config} />;

        default:
          return expectNever(viewModel.state);
      }
    };

    const renderDialog = () => {
      switch (viewModel.state.type) {
        case "idle":
        case "select":
          return null;

        case "rename":
          return (
            <InputDialog
              title="Edit cashback"
              label="Set name"
              saveActionLabel="Update"
              defaultValue={config.label}
              onSave={viewModel.onSaveClick}
              onCancel={viewModel.onCancelClick}
            />
          );

        default:
          return expectNever(viewModel.state);
      }
    };

    const drawerContent = renderDrawer();
    const isDrawerShown = Boolean(drawerContent);
    const dialogContent = renderDialog();
    const isDialogShown = Boolean(dialogContent);

    return (
      <>
        <AppBar sx={{ position: "relative" }} color="default" elevation={0}>
          <Toolbar>
            <Typography sx={{ ml: 2, mr: "auto" }} variant="h6" component="div">
              {config.label}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="edit"
              onClick={viewModel.onRenameClick}
            >
              <EditIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ overflow: "auto" }}>
          <List component="nav">
            {viewModel.cards.map((card) => (
              <ListItemButton
                key={card.id}
                onClick={() => viewModel.onCardClick(card.id)}
              >
                <ListItemText primary={card.label} />
                {Boolean(card.bindings) && (
                  <Badge
                    color="info"
                    badgeContent={card.bindings}
                    sx={{ mr: 1 }}
                  />
                )}
              </ListItemButton>
            ))}
          </List>
        </Box>
        <Drawer
          open={isDrawerShown}
          disableSwipeToOpen
          onClose={viewModel.onCancelClick}
        >
          {drawerContent}
        </Drawer>
        <Dialog open={isDialogShown} onClose={viewModel.onCancelClick}>
          {dialogContent}
        </Dialog>
      </>
    );
  }
);
