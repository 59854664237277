import { Box } from "@mui/material";
import SwipeableDrawer, {
  SwipeableDrawerProps,
} from "@mui/material/SwipeableDrawer";
import { useEffect, useState } from "react";
import styles from "./Drawer.module.css";

export interface DrawerProps
  extends Omit<SwipeableDrawerProps, "anchor" | "onClose" | "onOpen"> {
  onClose: VoidFunction;
}

export const Drawer = ({ open, onClose, children, ...rest }: DrawerProps) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <SwipeableDrawer
      {...rest}
      anchor="bottom"
      open={isOpen}
      disableSwipeToOpen
      onClose={() => setIsOpen(false)}
      SlideProps={{
        onExited: onClose,
      }}
      onOpen={() => {}}
    >
      <Box className={styles.drawer}>{children}</Box>
    </SwipeableDrawer>
  );
};
