import { KeyboardEventHandler, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Button from '@mui/material/Button';

export type ValueFormProps = {
  title: string;
  valueLabel: string;
  hintLabel: string;
  saveActionLabel?: string;
  defaultValue?: string;
  defaultHint?: string;
  type?: TextFieldProps['type'];
  onSave: (value: string, hint?: string) => void;
  onCancel: VoidFunction;
  onDelete?: VoidFunction;
};

export const ValueForm = ({
  title,
  valueLabel,
  hintLabel,
  saveActionLabel = 'Save',
  defaultValue,
  defaultHint,
  type = 'text',
  onSave,
  onCancel,
  onDelete,
}: ValueFormProps) => {
  const [value, setValue] = useState(defaultValue ?? '');
  const [hint, setHint] = useState(defaultHint ?? '');

  const handleEnterKey: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (value && event.key === 'Enter') {
      onSave(value, hint);
    }
  };

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ minWidth: 300 }}>
        <TextField
          autoFocus
          margin="dense"
          label={valueLabel}
          fullWidth
          variant="standard"
          value={value}
          type={type}
          inputProps={{ pattern: type === 'number' ? '[0-9]*' : undefined }}
          onChange={(event) => setValue(event.target.value)}
          onKeyUp={handleEnterKey}
        />
        <TextField
          margin="dense"
          label={hintLabel}
          fullWidth
          variant="standard"
          value={hint}
          type="text"
          onChange={(event) => setHint(event.target.value)}
          onKeyUp={handleEnterKey}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        {onDelete && (
          <Button onClick={onDelete} color="error">
            Delete
          </Button>
        )}
        <Button onClick={() => onSave(value, hint)} disabled={!value}>
          {saveActionLabel}
        </Button>
      </DialogActions>
    </>
  );
};
