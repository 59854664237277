import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import { expectNever } from "src/utils";
import { view, ViewModel } from "@yoskutik/react-vvm";
import { ConfirmDialog, InputDialog } from "src/components";
import { injectable } from "tsyringe";
import { CardsService } from "src/services";
import { makeObservable, observable } from "mobx";

type State =
  | {
      type: "idle";
    }
  | { type: "new" }
  | {
      type: "edit";
      id: number;
    }
  | { type: "delete"; id: number };

@injectable()
class CardsViewModel extends ViewModel {
  @observable state: State = { type: "idle" };

  constructor(private cardsService: CardsService) {
    super();

    makeObservable(this);
  }

  get cards() {
    return this.cardsService.cards;
  }

  get selectedCard() {
    switch (this.state.type) {
      case "idle":
      case "new":
        return undefined;

      case "edit":
      case "delete":
        return this.cardsService.find(this.state.id);

      default:
        return expectNever(this.state);
    }
  }

  onCreateClick = () => {
    this.state = { type: "new" };
  };

  onEditClick(id: number) {
    this.state = { type: "edit", id };
  }

  onDeleteClick(id: number) {
    this.state = { type: "delete", id };
  }

  onCancelClick = () => {
    this.state = { type: "idle" };
  };

  onSaveClick = (label: string): void => {
    switch (this.state.type) {
      case "idle":
        return;

      case "new":
        this.cardsService.add(label);

        return this.onCancelClick();

      case "edit":
        this.cardsService.update(this.state.id, label);

        return this.onCancelClick();

      case "delete":
        return;

      default:
        return expectNever(this.state);
    }
  };

  onConfirmClick = () => {
    switch (this.state.type) {
      case "idle":
      case "new":
      case "edit":
        return;

      case "delete":
        this.cardsService.remove(this.state.id);

        return this.onCancelClick();

      default:
        return expectNever(this.state);
    }
  };
}

export const Cards = view(CardsViewModel)(({ viewModel }) => {
  const renderDialog = () => {
    switch (viewModel.state.type) {
      case "idle":
        return null;

      case "new":
        return (
          <InputDialog
            title="New card"
            label="Card name"
            saveActionLabel="Add"
            onSave={viewModel.onSaveClick}
            onCancel={viewModel.onCancelClick}
          />
        );

      case "edit": {
        const card = viewModel.selectedCard;

        if (!card) {
          return null;
        }

        return (
          <InputDialog
            title="Edit card"
            label="Card name"
            saveActionLabel="Save"
            defaultValue={card.label}
            onSave={viewModel.onSaveClick}
            onCancel={viewModel.onCancelClick}
          />
        );
      }

      case "delete": {
        const card = viewModel.selectedCard;

        if (!card) {
          return null;
        }

        return (
          <ConfirmDialog
            title={`Are you sure you want to delete "${card.label}"?`}
            yesNo
            onConfirm={viewModel.onConfirmClick}
            onCancel={viewModel.onCancelClick}
          />
        );
      }

      default:
        return expectNever(viewModel.state);
    }
  };

  const dialogContent = renderDialog();
  const isDialogShown = Boolean(dialogContent);

  return (
    <Box>
      <List component="nav">
        {viewModel.cards.map((card) => (
          <ListItemButton
            key={card.id}
            onClick={() => viewModel.onEditClick(card.id)}
          >
            <ListItemText inset primary={card.label} />
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton
                aria-label="Delete"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();

                  viewModel.onDeleteClick(card.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          </ListItemButton>
        ))}
        <ListItemButton onClick={viewModel.onCreateClick}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Add card" />
        </ListItemButton>
      </List>
      <Dialog open={isDialogShown} onClose={viewModel.onCancelClick}>
        {dialogContent}
      </Dialog>
    </Box>
  );
});
