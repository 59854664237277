import { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Category } from "src/utils";
import { Box, IconButton } from "@mui/material";
import ImageIcon from "@mui/icons-material/BrokenImageOutlined";
import { Icon, Icons } from "src/components";
import { IconPicker } from "src/components";

export type CategoryFormProps = {
  title: string;
  saveActionLabel: string;
  category?: Category;
  onSave: (category: Category) => void;
  onCancel: VoidFunction;
  onDelete?: VoidFunction;
};

type State = {
  icon?: Icon;
  label: string;
};

export const CategoryForm = ({
  title,
  saveActionLabel,
  category,
  onSave,
  onCancel,
  onDelete,
}: CategoryFormProps) => {
  const [state, setState] = useState<State>({
    icon: category?.icon,
    label: category?.label ?? "",
  });

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ minWidth: 350 }}>
        <DialogContentText>Configure category.</DialogContentText>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconPicker
            icon={state.icon}
            onSelect={(icon) => setState((state) => ({ ...state, icon }))}
          />
          <TextField
            sx={{ ml: 2 }}
            autoFocus
            margin="dense"
            id="name"
            label="Category name"
            type="text"
            fullWidth
            variant="standard"
            value={state.label}
            onChange={(event) =>
              setState((state) => ({ ...state, label: event.target.value }))
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        {onDelete && (
          <Button onClick={onDelete} color="error">
            Delete
          </Button>
        )}
        <Button
          onClick={() => {
            if (state.icon && state.label) {
              onSave({
                id: 0,
                icon: state.icon,
                label: state.label,
              });
            }
          }}
          disabled={!state.icon || !state.label}
        >
          {saveActionLabel}
        </Button>
      </DialogActions>
    </>
  );
};
