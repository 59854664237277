import { makeObservable, observable, action } from "mobx";
import { Card } from "src/utils";
import { byId, maxId } from "src/utils/id";
import { singleton } from "tsyringe";
import { StorageService } from "./StorageService";

@singleton()
export class CardsService {
  @observable cards: Card[];

  constructor(private storageService: StorageService) {
    makeObservable(this);

    this.cards = this.storageService.read("cards") ?? [];
  }

  private getNewId(): number {
    return this.cards.reduce(maxId, -1) + 1;
  }

  private findIndex(id: number): number {
    return this.cards.findIndex(byId(id));
  }

  private store(): void {
    this.storageService.write("cards", this.cards);
  }

  @action add(label: string): void {
    this.cards.push({
      id: this.getNewId(),
      label,
    });

    this.store();
  }

  @action update(id: number, label: string): void {
    const card = this.find(id);

    if (card && card.label !== label) {
      card.label = label;

      this.store();
    }
  }

  @action remove(id: number): void {
    const index = this.findIndex(id);

    if (index !== -1) {
      this.cards.splice(index, 1);

      this.store();
    }
  }

  find(id: number): Card | undefined {
    return this.cards.find(byId(id));
  }

  set(cards: Card[]) {
    this.cards = cards;

    this.store();
  }
}
