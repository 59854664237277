import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useState, useRef, useEffect, useMemo } from 'react';
import CreditCard from '@mui/icons-material/CreditCard';
import SettingsIcon from '@mui/icons-material/Settings';
import { Main, Settings } from 'src/views';
import { expectNever } from 'src/utils';
import styles from './App.module.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { injectable } from 'tsyringe';
import { ViewModel, view } from '@yoskutik/react-vvm';
import { Theme, ThemeService } from './services/ThemeService';
import { makeObservable } from 'mobx';

type Page = 'main' | 'settings';

@injectable()
class AppViewModel extends ViewModel {
  constructor(private themeService: ThemeService) {
    super();

    makeObservable(this);
  }

  get theme(): Theme {
    return this.themeService.theme;
  }

  set theme(theme: Theme) {
    this.themeService.set(theme);
  }
}

const App = view(AppViewModel)(({ viewModel }) => {
  const [page, setPage] = useState<Page>('main');
  const ref = useRef<HTMLDivElement>(null);
  const systemMode = useMediaQuery('(prefers-color-scheme: dark)')
    ? 'dark'
    : 'light';
  const mode = viewModel.theme === 'auto' ? systemMode : viewModel.theme;

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
        },
      }),
    [mode]
  );

  useEffect(() => {
    (ref.current as HTMLDivElement).ownerDocument.body.scrollTop = 0;
  }, [page]);

  const renderPage = () => {
    switch (page) {
      case 'main':
        return <Main />;

      case 'settings':
        return <Settings />;

      default:
        return expectNever(page);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ pb: 7 }} ref={ref}>
        <CssBaseline />
        <Box sx={{ overflow: 'auto', pb: '5rem', maxHeight: '100vh' }}>
          {renderPage()}
        </Box>
        <Paper
          sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={page}
            onChange={(event, newValue) => {
              setPage(newValue);
            }}
            className={styles.nav}
          >
            <BottomNavigationAction
              label="Cashback"
              icon={<CreditCard />}
              value="main"
            />
            <BottomNavigationAction
              label="Settings"
              icon={<SettingsIcon />}
              value="settings"
            />
          </BottomNavigation>
        </Paper>
      </Box>
    </ThemeProvider>
  );
});

export default App;
