import { Tile } from "../Tile";
import Grid from "@mui/material/Unstable_Grid2";
import { Icon } from "../icons";

export type TileConfig = {
  icon: Icon;
  label?: string;
  active?: boolean;
  disabled?: boolean;
  badge?: number;
};

export type TilesProps<T extends TileConfig> = {
  tiles: T[];
  large?: boolean;
  onSelect?: (item: T) => void;
};

export function Tiles<T extends TileConfig>({
  tiles,
  large,
  onSelect,
}: TilesProps<T>) {
  return (
    <Grid container spacing={1} padding="0.5rem" margin="0">
      {tiles.map((tile, index) => (
        <Grid
          key={index}
          xs={4}
          sm={3}
          md={2}
          lg={1.5}
          xl={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="0.5rem"
        >
          <Tile
            icon={tile.icon}
            active={tile.active}
            disabled={tile.disabled}
            badge={tile.badge}
            large={large}
            onClick={() => onSelect?.(tile)}
          >
            {tile.label}
          </Tile>
        </Grid>
      ))}
    </Grid>
  );
}
