import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import CardIcon from '@mui/icons-material/CreditCard';
import AppsIcon from '@mui/icons-material/Apps';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/EditOutlined';
import UploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/FileDownload';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import Dialog from '@mui/material/Dialog';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import { Card, CashbackConfig, Category, expectNever } from 'src/utils';
import { Drawer, InputDialog } from 'src/components';
import { Cards } from '../Cards';
import { Categories } from '../Categories';
import { ConfirmDialog } from 'src/components';
import { view, ViewModel } from '@yoskutik/react-vvm';
import { action, makeObservable, observable } from 'mobx';
import { CardsService, CashbackService, CategoriesService } from 'src/services';
import { injectable } from 'tsyringe';
import { Cashback } from '../Cashback';
import { ChangeEvent } from 'react';
import { Theme, ThemeService } from 'src/services/ThemeService';

type State =
  | {
      type: 'idle';
    }
  | {
      type: 'categories';
    }
  | {
      type: 'cards';
    }
  | {
      type: 'new-cashback';
    }
  | {
      type: 'edit-cashback';
      id: number;
    }
  | {
      type: 'delete-cashback';
      id: number;
    };

@injectable()
class SettingsViewModel extends ViewModel {
  @observable state: State = { type: 'idle' };

  constructor(
    private cashbackService: CashbackService,
    private cardsService: CardsService,
    private categoriesService: CategoriesService,
    private themeService: ThemeService
  ) {
    super();

    makeObservable(this);
  }

  get items() {
    return this.cashbackService.cashback.items;
  }

  get curentId() {
    return this.cashbackService.cashback.current;
  }

  get currentItem() {
    switch (this.state.type) {
      case 'idle':
      case 'categories':
      case 'cards':
      case 'new-cashback':
        return undefined;

      case 'edit-cashback':
      case 'delete-cashback':
        return this.cashbackService.find(this.state.id);

      default:
        return expectNever(this.state);
    }
  }

  onCategoriesClick = () => {
    this.state = { type: 'categories' };
  };

  onCardsClick = () => {
    this.state = { type: 'cards' };
  };

  onCashbackClick(id: number) {
    this.cashbackService.select(id);
  }

  onNewCashbackClick = () => {
    this.state = { type: 'new-cashback' };
  };

  onCreateCashbackClick(label: string) {
    const id = this.cashbackService.add(label);

    this.state = { type: 'edit-cashback', id };
  }

  onEditCashbackClick(id: number) {
    this.state = { type: 'edit-cashback', id };
  }

  onDeleteCashbackClick(id: number) {
    this.state = { type: 'delete-cashback', id };
  }

  onConfirmClick = () => {
    switch (this.state.type) {
      case 'idle':
      case 'categories':
      case 'cards':
      case 'new-cashback':
      case 'edit-cashback':
        return;

      case 'delete-cashback':
        this.cashbackService.delete(this.state.id);

        return this.onCancelClick();

      default:
        return expectNever(this.state);
    }
  };

  onDownloadClick = () => {
    const json = {
      categories: this.categoriesService.categories,
      cards: this.cardsService.cards,
      cashback: this.cashbackService.cashback,
    };
    const content = JSON.stringify(json);

    const base64 = btoa(unescape(encodeURIComponent(content)));
    const url = `data:image/gif;base64,${base64}`;

    const link = document.createElement('A') as HTMLAnchorElement;
    link.download = 'cashback.json';
    link.href = url;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  onCancelClick = () => {
    this.state = { type: 'idle' };
  };

  private parseJson(json: string) {
    const { categories, cards, cashback } = JSON.parse(json) as {
      categories: Category[];
      cards: Card[];
      cashback: CashbackConfig;
    };

    this.categoriesService.set(categories);
    this.cardsService.set(cards);
    this.cashbackService.set(cashback);
  }

  onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);

    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      if (typeof fileReader.result === 'string') {
        this.parseJson(fileReader.result);
      }
    });
    fileReader.readAsText(file, 'utf8');
  };

  get theme() {
    return this.themeService.theme;
  }

  @action toggleAuto = () => {
    this.themeService.set(
      this.themeService.theme === 'auto' ? 'light' : 'auto'
    );
  };

  @action toggleDark = () => {
    if (this.themeService.theme === 'auto') {
      return;
    }

    this.themeService.set(
      this.themeService.theme === 'dark' ? 'light' : 'dark'
    );
  };
}

export const Settings = view(SettingsViewModel)(({ viewModel }) => {
  const renderDrawer = () => {
    switch (viewModel.state.type) {
      case 'categories':
        return <Categories />;

      case 'cards':
        return <Cards />;

      case 'edit-cashback':
        const item = viewModel.currentItem;

        return item ? <Cashback config={item} /> : null;

      case 'idle':
      case 'new-cashback':
      case 'delete-cashback':
        return null;

      default:
        return expectNever(viewModel.state);
    }
  };

  const renderDialog = () => {
    switch (viewModel.state.type) {
      case 'categories':
      case 'cards':
      case 'edit-cashback':
      case 'idle':
        return null;

      case 'new-cashback':
        return (
          <InputDialog
            title="New cashback set"
            label="Set name"
            saveActionLabel="Create"
            onSave={(label) => viewModel.onCreateCashbackClick(label)}
            onCancel={viewModel.onCancelClick}
          />
        );

      case 'delete-cashback': {
        const cashbackItem = viewModel.currentItem;

        if (!cashbackItem) {
          return null;
        }

        return (
          <ConfirmDialog
            title={`Are you sure you want to delete "${cashbackItem?.label}"?`}
            yesNo
            onConfirm={viewModel.onConfirmClick}
            onCancel={viewModel.onCancelClick}
          />
        );
      }

      default:
        return expectNever(viewModel.state);
    }
  };

  const drawerContent = renderDrawer();
  const isDrawerShown = Boolean(drawerContent);
  const dialogContent = renderDialog();
  const isDialogShown = Boolean(dialogContent);

  return (
    <Box>
      <List component="nav">
        <ListItemButton onClick={viewModel.onCategoriesClick}>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary="Categories" />
        </ListItemButton>
        <ListItemButton onClick={viewModel.onCardsClick}>
          <ListItemIcon>
            <CardIcon />
          </ListItemIcon>
          <ListItemText primary="Cards" />
        </ListItemButton>
      </List>
      <Divider />
      <List component="nav">
        {viewModel.items.map((item) => {
          const isSelected = item.id === viewModel.curentId;

          return (
            <ListItemButton
              key={item.id}
              onClick={() => viewModel.onCashbackClick(item.id)}
            >
              {isSelected && (
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              )}
              <ListItemText inset={!isSelected} primary={item.label} />
              <Stack direction="row" alignItems="center" spacing={1}>
                <IconButton
                  aria-label="Copy"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    viewModel.onEditCashbackClick(item.id);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="Delete"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    viewModel.onDeleteCashbackClick(item.id);
                  }}
                  disabled={isSelected}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </ListItemButton>
          );
        })}

        <ListItemButton onClick={viewModel.onNewCashbackClick}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Add month" />
        </ListItemButton>
      </List>
      <Divider />
      <List component="nav" subheader={<ListSubheader>Theme</ListSubheader>}>
        <ListItemButton onClick={viewModel.toggleAuto}>
          <ListItemIcon>
            <Brightness4Icon />
          </ListItemIcon>
          <ListItemText primary="System" />
          <Switch edge="end" checked={viewModel.theme === 'auto'} />
        </ListItemButton>
        <ListItemButton
          disabled={viewModel.theme === 'auto'}
          onClick={viewModel.toggleDark}
        >
          <ListItemIcon>
            <DarkModeIcon />
          </ListItemIcon>
          <ListItemText primary="Dark" />
          <Switch edge="end" checked={viewModel.theme === 'dark'} />
        </ListItemButton>
      </List>
      <Divider />
      <List component="nav">
        <ListItemButton onClick={viewModel.onDownloadClick}>
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          <ListItemText primary="Download" />
        </ListItemButton>
        <ListItemButton component="label">
          <ListItemIcon>
            <UploadIcon />
          </ListItemIcon>
          <ListItemText primary="Upload" />
          <input type="file" hidden onChange={viewModel.onUpload} />
        </ListItemButton>
      </List>
      <Drawer
        open={isDrawerShown}
        disableSwipeToOpen
        onClose={viewModel.onCancelClick}
      >
        {drawerContent}
      </Drawer>
      <Dialog open={isDialogShown} onClose={viewModel.onCancelClick}>
        {dialogContent}
      </Dialog>
    </Box>
  );
});
