import { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Icon, IconNames, Icons } from "../icons";
import { TileConfig, Tiles } from "../Tiles";
import { Search } from "../Search";
import { Box } from "@mui/material";

export type IconPickerProps = {
  icon?: Icon;
  onSelect: (icon: Icon) => void;
};

type State = { type: "idle" } | { type: "selecting"; search: string };

export const IconPicker = ({ icon, onSelect }: IconPickerProps) => {
  const [state, setState] = useState<State>({ type: "idle" });

  const closeDialog = () => setState({ type: "idle" });

  const currentIcon: Icon = icon ?? "BrokenImageOutlined";
  const IconComponent = Icons[currentIcon];
  const search = state.type === "selecting" ? state.search : "";
  const icons: TileConfig[] = useMemo(() => {
    const lowerSearch = search.trim().toLowerCase();

    return IconNames.filter((icon) => icon.toLowerCase().includes(lowerSearch))
      .map((icon) => ({
        icon,
      }))
      .slice(0, 100);
  }, [search]);

  return (
    <div>
      <IconButton onClick={() => setState({ type: "selecting", search: "" })}>
        <IconComponent />
      </IconButton>
      <Dialog
        fullScreen
        open={state.type === "selecting"}
        onClose={closeDialog}
        scroll="paper"
      >
        <AppBar sx={{ position: "relative" }} color="default">
          <Toolbar>
            <Typography sx={{ ml: 2, mr: 1 }} variant="h6" component="div">
              Icons
            </Typography>
            <Search
              value={(state.type === "selecting" && state.search) || ""}
              onChange={(search) => setState({ type: "selecting", search })}
            />
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ overflow: "auto" }}>
          <Tiles
            tiles={icons}
            large
            onSelect={(icon) => {
              closeDialog();
              onSelect(icon.icon);
            }}
          />
        </Box>
      </Dialog>
    </div>
  );
};
