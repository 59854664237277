import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Badge, Box, Dialog } from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import { Card, CashbackSet, expectNever } from 'src/utils';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { view, ViewModel } from '@yoskutik/react-vvm';
import { CardsService, CashbackService, CategoriesService } from 'src/services';
import { injectable } from 'tsyringe';
import { FC } from 'react';
import { makeObservable, observable } from 'mobx';
import {
  ConfirmDialog,
  Drawer,
  Icon,
  InputDialog,
  Tiles,
} from 'src/components';
import { ValueForm } from './ValueForm';

export type CardCashbackProps = {
  card: Card;
  config: CashbackSet;
};

type CategoryConfig = {
  id: number;
  icon: Icon;
  label: string;
  badge?: number;
};

type State =
  | { type: 'idle' }
  | { type: 'select'; id: number }
  | { type: 'delete'; id: number };

@injectable()
class CardCashbackViewModel extends ViewModel<unknown, CardCashbackProps> {
  @observable state: State = { type: 'idle' };

  constructor(
    private categoriesService: CategoriesService,
    private cashbackService: CashbackService
  ) {
    super();

    makeObservable(this);
  }

  get currentCardId() {
    return this.viewProps.card.id;
  }

  get currentCategoryId() {
    return this.state.type === 'idle' ? undefined : this.state.id;
  }

  get categories(): CategoryConfig[] {
    return this.categoriesService.categories.map((category) => ({
      ...category,
      badge: this.viewProps.config.items.find(
        (item) =>
          item.card === this.currentCardId && item.category === category.id
      )?.value,
    }));
  }

  get currentItem() {
    return this.viewProps.config.items.find(
      (item) =>
        item.card === this.currentCardId &&
        item.category === this.currentCategoryId
    );
  }

  onCategoryClick(id: number) {
    this.state = { type: 'select', id };
  }

  onSaveClick = (strValue: string, hint?: string) => {
    if (this.state.type !== 'select') {
      return;
    }

    const value = Number(strValue);

    if (isNaN(value)) {
      return;
    }

    this.cashbackService.setValue(
      this.viewProps.config.id,
      this.viewProps.card.id,
      this.state.id,
      value,
      hint
    );

    this.onCancelClick();
  };

  onDeleteClick = () => {
    if (this.state.type !== 'select') {
      return;
    }

    this.state = { type: 'delete', id: this.state.id };
  };

  onConfirmClick = () => {
    if (this.state.type !== 'delete') {
      return;
    }

    this.cashbackService.resetValue(
      this.viewProps.config.id,
      this.viewProps.card.id,
      this.state.id
    );

    this.onCancelClick();
  };

  onCancelClick = () => {
    this.state = { type: 'idle' };
  };

  onCancelDeleteClick = () => {
    if (this.state.type !== 'delete') {
      return;
    }

    this.state = { type: 'select', id: this.state.id };
  };
}

export const CardCashback: FC<CardCashbackProps> = view(CardCashbackViewModel)(
  ({ viewModel, card, config }) => {
    const renderDialog = () => {
      switch (viewModel.state.type) {
        case 'idle':
          return null;

        case 'select':
        case 'delete':
          const isEdit = viewModel.currentItem !== undefined;

          return (
            <>
              <ValueForm
                title="Edit cashback"
                valueLabel="Set cachback value"
                hintLabel="Hint (optional)"
                saveActionLabel={isEdit ? 'Update' : 'Save'}
                defaultValue={viewModel.currentItem?.value.toString()}
                defaultHint={viewModel.currentItem?.hint}
                type="number"
                onSave={viewModel.onSaveClick}
                onCancel={viewModel.onCancelClick}
                onDelete={isEdit ? viewModel.onDeleteClick : undefined}
              />
              <Dialog
                open={viewModel.state.type === 'delete'}
                onClose={viewModel.onCancelDeleteClick}
              >
                <ConfirmDialog
                  title="Are you sure you want to reset this cashback?"
                  yesNo
                  onCancel={viewModel.onCancelDeleteClick}
                  onConfirm={viewModel.onConfirmClick}
                />
              </Dialog>
            </>
          );

        default:
          return expectNever(viewModel.state);
      }
    };

    const dialogContent = renderDialog();
    const isDialogShown = Boolean(dialogContent);

    return (
      <>
        <AppBar sx={{ position: 'relative' }} color="default" elevation={0}>
          <Toolbar>
            <Typography sx={{ ml: 2, mr: 'auto' }} variant="h6" component="div">
              Cashback for "{card.label}"
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ overflow: 'auto' }}>
          <Tiles
            tiles={viewModel.categories}
            onSelect={(category) => viewModel.onCategoryClick(category.id)}
          />
        </Box>
        <Dialog open={isDialogShown} onClose={viewModel.onCancelClick}>
          {dialogContent}
        </Dialog>
      </>
    );
  }
);
