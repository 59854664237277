import { Drawer, Icon, InfoDialog, Tiles } from 'src/components';
import { view, ViewModel } from '@yoskutik/react-vvm';
import { injectable } from 'tsyringe';
import { CardsService, CashbackService, CategoriesService } from 'src/services';
import { makeObservable, observable } from 'mobx';
import {
  AppBar,
  Badge,
  Box,
  Dialog,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { useState } from 'react';

type Cashback = {
  card: string;
  value: number;
  hint?: string;
};

type Category = {
  id: number;
  icon: Icon;
  label: string;
  disabled: boolean;
  cards: Cashback[];
};

type State = { type: 'idle' } | { type: 'category'; id: number };

@injectable()
class MainViewModel extends ViewModel {
  @observable state: State = { type: 'idle' };

  categories: Category[];

  constructor(
    cardsService: CardsService,
    categoriesService: CategoriesService,
    cashbackService: CashbackService
  ) {
    super();

    makeObservable(this);

    const cardsMap = new Map<number, string>(
      cardsService.cards.map((card) => [card.id, card.label])
    );
    const categoriesMap: Record<number, Cashback[]> = {};
    for (let item of cashbackService.currentConfig?.items ?? []) {
      const cashback: Cashback = {
        card: cardsMap.get(item.card)!,
        value: item.value,
        hint: item.hint,
      };
      if (item.category in categoriesMap) {
        categoriesMap[item.category].push(cashback);
      } else {
        categoriesMap[item.category] = [cashback];
      }
    }

    this.categories = categoriesService.categories.map((category) => {
      const cards: Cashback[] =
        cashbackService.currentConfig?.items
          .filter((item) => item.category === category.id)
          .map((item) => ({
            card: cardsMap.get(item.card)!,
            value: item.value,
            hint: item.hint,
          })) ?? [];

      cards.sort((card1, card2) => card2.value - card1.value);

      return {
        ...category,
        cards,
        disabled: cards.length === 0,
      };
    });

    this.categories.sort(
      (c1, c2) => (c1.disabled ? 1 : 0) - (c2.disabled ? 1 : 0)
    );
  }

  get category(): Category | undefined {
    if (this.state.type !== 'category') {
      return undefined;
    }

    const id = this.state.id;

    return this.categories.find((category) => category.id === id);
  }

  onSelectCategory = (category: Category) => {
    this.state = { type: 'category', id: category.id };
  };

  onClose = () => {
    this.state = { type: 'idle' };
  };
}

export const Main = view(MainViewModel)(({ viewModel }) => {
  const [hint, setHint] = useState<string>();

  const handleDialogClose = () => setHint(undefined);

  return (
    <>
      <Tiles
        tiles={viewModel.categories}
        onSelect={viewModel.onSelectCategory}
      />
      <Drawer
        open={viewModel.state.type === 'category'}
        disableSwipeToOpen
        onClose={viewModel.onClose}
      >
        <AppBar sx={{ position: 'relative' }} color="default" elevation={0}>
          <Toolbar>
            <Typography sx={{ ml: 2, mr: 'auto' }} variant="h6" component="div">
              {viewModel.category?.label}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ overflow: 'auto' }}>
          <List component="nav">
            {viewModel.category?.cards.map((cashback, index) => (
              <ListItemButton
                key={index}
                onClick={() => setHint(cashback.hint)}
              >
                <ListItemText
                  primary={
                    <>
                      {cashback.card}
                      {cashback.hint && (
                        <Badge
                          color="primary"
                          variant="dot"
                          sx={{ ml: 1, top: -1 }}
                        />
                      )}
                    </>
                  }
                />
                <Badge
                  color="info"
                  badgeContent={cashback.value}
                  sx={{ mr: 1 }}
                />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Drawer>
      <Dialog open={Boolean(hint)} onClose={handleDialogClose}>
        {hint && (
          <InfoDialog title="Hint" content={hint} onClose={handleDialogClose} />
        )}
      </Dialog>
    </>
  );
});
