import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

export type ConfirmDialogProps = {
  title: string;
  yesNo?: boolean;
  confirmActionLabel?: string;
  cancelActionLabel?: string;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
};

export const ConfirmDialog = ({
  title,
  yesNo,
  confirmActionLabel = yesNo ? "Yes" : "Configm",
  cancelActionLabel = yesNo ? "No" : "Cancel",
  onConfirm,
  onCancel,
}: ConfirmDialogProps) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogActions>
      <Button onClick={onCancel}>{cancelActionLabel}</Button>
      <Button onClick={onConfirm}>{confirmActionLabel}</Button>
    </DialogActions>
  </>
);
